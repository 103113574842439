<template>
  <div>
    <Header theme="white"></Header>
    <div class="article-wrap">
      <div class="empty-nav"></div>
      <div class="max-center">
        <h1>隐私与安全</h1>
        <p>
          本隐私保护声明适用于使用重庆云昇新控智能科技有限公司网站以及网站提供的各种服务的用户。我们郑重承诺并尊重、保护用户的个人隐私，未经用户授权我们不会公布与用户个人有关的资料，除非有法律或程序要求。我们将定期检查隐私保护措施，跟踪安全技术的发展趋势，更新技术和管理措施。</p>
        <p>1、用户使用重庆云昇新控智能科技有限公司网站的投诉与咨询、邮件订阅等服务需要提供必要的个人资料，通常包括：姓名、性别、住址、联系电话和电子邮箱。我们将对用户信息和有关需求内容进行保密管理。
        </p>
        <p>
          2、我们将采取技术和管理手段保护用户的隐私。用户提供给我们敏感信息时，我们将采取合理的步骤保护用户的敏感信息，我们也将采取合理的安全手段保护已存储的个人信息。本网站将对用户所提供的资料进行严格的管理及保护，将使用相应的技术，防止用户的个人资料丢失、被盗用或遭改变。</p>
        <p>
          3、本网站有时会使用cookies技术，以便我们知道哪些栏目受欢迎，使用户在访问我们的网站时得到更好的服务。cookies不会跟踪个人信息。当用户注册我们的网站时，将会使用到cookies。在这种情况下，网站会存储有用信息，使在用户再次访问我们的网站时可辨认用户的身份，从而向用户提供感兴趣的信息资料或储存密码，以便用户访问本网站的某些特定栏目时不必每次输入密码。</p>
        <p>4、重庆云昇新控智能科技有限公司网站将严格保守用户的个人隐私，承诺未在用户同意的情况下不将用户的个人信息任意披露。但是，在以下几个例外情形下，将无法保证前述的承诺，而披露用户的相关信息。这些情形包括但不限于：</p>
        <p>
          （1）当用户在本网站的行为违反了《计算机信息网络国际联网安全保护管理办法》、《互联网信息服务管理办法》、《互联网电子公告服务管理规定》以及《全国人大常委会关于维护互联网安全的决定》，或可能损害或妨碍其他网友的权益或导致他人遭受损害，披露用户的个人资料是为了辨识、联络或采取法律行动所必要的行动时。</p>
        <p>（2）法律法规所规定的必须披露或公开的个人信息。</p>
        <p>（3）当司法机关或其它授权机关依法执行公务，要求网站公开特定个人资料时。</p>
        <p>
          5、重庆云昇新控智能科技有限公司网站采取了完备的技术措施保护用户的信息的完整性和秘密性。但由于不可抗力或者因计算机病毒感染、黑客攻击等特殊外力侵扰，导致用户信息破坏、泄密并受到损失的，重庆云昇新控智能科技有限公司网站不承担任何法律责任，但我们将采取必要措施尽力减少用户的损失。
        </p>
        <p>6、任何时候如果用户认为重庆云昇新控智能科技有限公司网站没有遵守本声明时，请发送电子邮件到<a href="mailto:marketing@yunsxk.com" style="color: #333333"
                                                           target="_blank">marketing@yunsxk.com</a>通知我们，我们会尽一切努力，采取相应的改进措施。
        </p>
      </div>
    </div>
    <Footer></Footer>
    <BackTop></BackTop>
  </div>
</template>
<script>
import Header from '../../components/Header.vue'
import Footer from '../../components/Footer.vue'
import BackTop from '../../components/BackTop.vue'

export default {
  metaInfo: {
    meta: [
      {
        name: 'keyWords',
        content: '隐私与安全,云昇新控,智慧水务,智慧工厂,自动化系统集成,智慧照明,重庆云昇新控智能科技有限公司'
      },
      {
        name: 'description',
        content: '云昇新控隐私与安全,重庆云昇新控智能科技有限公司'
      }
    ]
  },
  components: {
    Header,
    Footer,
    BackTop
  },
  data () {
    return {}
  },
  methods: {}
}
</script>
<style lang="less">
.article-wrap {
  .max-center {
    text-align: left;
    font-family: Microsoft YaHei;
    max-width: 1200px;
    height: auto;
    margin: 0 auto;
    padding: 2% 0 5%;
  }

  h1 {
    text-align: center;
    font-size: 36px;
    font-weight: bold;
    color: #1890FF;
    line-height: 3;
  }

  p {
    text-indent: 2em;
    line-height: 2;
  }
}
</style>
